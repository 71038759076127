import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import MyAppointmentForm from './MyAppointmentForm';

const AddMyAppointmentDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translations');

  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>{t('Appointment')}</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <MyAppointmentForm onClose={onClose}  />
      </DialogContent>
    </Dialog>
  );
};
export default AddMyAppointmentDialog;
