import createCache from '@emotion/cache';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import withAppProviders from './withAppProviders';
import settingsConfig from 'app/configs/settingsConfig';
import handleError from '@fuse/core/errorHandler';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import dayjs from 'dayjs';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import { selectMainThemeLight } from 'app/store/fuse/settingsSlice';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './auth/AuthContext';
import { CacheProvider } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNavigationItem } from './store/fuse/navigationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isBetween from 'dayjs/plugin/isBetween';
import { useSpItemsListMutation } from './services/spApi';
import { useCheckSubordinateQuery } from './services/shtatApi';
import { useUser } from './hooks/useUser';
import JwtService from './auth/services/jwtService';
import { setHasSubordinates } from './store/userSlice';
import { useCheckAppointmentManagerQuery } from './services/resourceApi';
import 'dayjs/locale/ru';

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 1000,
  sortField: 'id',
  sortOrder: 0,
};

const App = () => {
  dayjs.locale('ru');
  dayjs.extend(isBetween);
  const [isTranslationsFetched, setIsTranslationsFetched] = useState(false);
  const [getTranslationsList, { isLoading: isTranslationsLoading }] = useSpItemsListMutation();
  const { isHR, isTop, user } = useUser();
  const isDarkMode = useSelector((state) => state.themeSlice.isDarkMode);
  const selectedTheme = useSelector(isDarkMode ? selectMainThemeDark : selectMainThemeLight);
  const { data: hasSubordinates = false, isSuccess: gotSubordinatesStatus } =
    useCheckSubordinateQuery({}, { skip: !user?.id });
  const { data: isAppointmentManager, isSuccess: gotAppointmentStatus } =
    useCheckAppointmentManagerQuery({}, { skip: !user?.id });
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (user && user.staffId === 0) {
      dispatch(removeNavigationItem('apps.skud'));
      dispatch(removeNavigationItem('apps.projects'));
      dispatch(removeNavigationItem('apps.calendar'));
      dispatch(removeNavigationItem('apps.chat'));
    }
  }, [user]);

  useEffect(() => {
    if (isTop || user?.staffId === 0) {
      dispatch(removeNavigationItem('workload'));
      dispatch(removeNavigationItem('skud-departments'));
      dispatch(removeNavigationItem('daily-attendances'));
    }
  }, [isTop]);

  useEffect(() => {
    if (hasSubordinates) {
      dispatch(setHasSubordinates(true));
    }
  }, [hasSubordinates]);

  useEffect(() => {
    if ((!isAppointmentManager && gotAppointmentStatus) || user?.staffId === 0) {
      dispatch(removeNavigationItem('apps.appointments'));
    }
  }, [isAppointmentManager, gotAppointmentStatus]);

  useEffect(() => {
    if (gotSubordinatesStatus && !(isHR || isTop) && !hasSubordinates) {
      dispatch(removeNavigationItem('apps.skud'));
    }
  }, [hasSubordinates, gotSubordinatesStatus, isHR, isTop]);

  const fetchTranslations = () => {
    getTranslationsList({ tableName: 'Lang18s', ...initialParams })
      .unwrap()
      .then((response) => {
        const translations = response.items.reduce(
          (acc, t) => ({
            ru: {
              ...acc['ru'],
              [t.code]: t.nameRu,
            },
            uz: {
              ...acc['uz'],
              [t.code]: t.nameUz,
            },
          }),
          {}
        );
        i18n.addResourceBundle('ru', 'translations', translations?.ru, true, true);
        i18n.addResourceBundle('uz', 'translations', translations?.uz, true, true);
        setIsTranslationsFetched(true);
      })
      .catch(handleError);
  };

  useEffect(() => {
    let interval = null;
    if (user && user.staffId >= 0 && isTranslationsFetched) {
      if (JwtService.isFewMinutesToTokenDeathLeft()) {
        JwtService.refreshToken();
      }
      interval = setInterval(() => {
        if (JwtService.isFewMinutesToTokenDeathLeft()) {
          JwtService.refreshToken();
        }
      }, JwtService.tokenRefreshInterval);
    }
    return () => clearInterval(interval);
  }, [user, isTranslationsFetched]);

  useEffect(() => {
    if (user?.id && !isTranslationsFetched && !isTranslationsLoading) {
      fetchTranslations();
    }
  }, [user]);

  return (
    <CacheProvider
      value={createCache({
        key: 'muiltr',
        stylisPlugins: [],
        insertionPoint: document.getElementById('emotion-insertion-point'),
      })}
    >
      <FuseTheme theme={selectedTheme} direction={'ltr'}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.roles}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
