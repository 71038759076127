import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';
import { DayPilot } from '@daypilot/daypilot-lite-react';

export const resourceApi = createApi({
  reducerPath: 'resourceApi',
  baseQuery: rtkBaseQuery('/resource'),
  tagTypes: [
    'Calendars',
    'Resources',
    'Events',
    'Appointments',
    'ManagersAndSecretaries',
    'AllAppointments',
    'AppointmentPurposes',
  ],
  endpoints: (builder) => ({
    // Calendars
    createCalendar: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateCalendar',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Calendars'],
    }),
    getCalendarList: builder.query({
      query: (id) => ({
        url: `/Resource/GetCalendarList?type=${id}`,
      }),
      providesTags: ['Calendars'],
    }),
    editCalendar: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditCalendar',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Calendars'],
    }),
    deleteCalendar: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteCalendar?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Calendars'],
    }),
    // Resources
    getResourceList: builder.mutation({
      query: (body) => ({
        url: '/Resource/GetResourceList',
        method: 'POST',
        body,
      }),
      providesTags: ['Resources'],
    }),
    createResource: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateResource',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Resources'],
    }),
    getResourceById: builder.query({
      query: (id) => `/Resource/GetResourceById/${id}`,
    }),
    EditResource: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditResource',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Resources'],
    }),
    deleteResource: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteResource?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Resources'],
    }),

    // Events
    createEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateEvent',
        method: 'POST',
        body,
      }),
    }),
    getEventList: builder.mutation({
      query: (body) => ({
        url: '/Resource/GetEventList',
        method: 'POST',
        body,
      }),
      providesTags: ['Events'],
    }),
    editEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditEvent',
        method: 'POST',
        body,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteEvent?id=${id}`,
        method: 'DELETE',
      }),
    }),
    AddStaffToEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/AddStaffToEvent',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Events'],
    }),
    RemoveStaffFromEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/RemoveStaffFromEvent',
        method: 'POST',
        body,
      }),
    }),
    //Appointments
    createAppointment: builder.mutation({
      query: (body) => ({
        url: '/Appointment/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Appointments'],
    }),
    editAppointment: builder.mutation({
      query: (body) => ({
        url: `/Appointment/Edit/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Appointments'],
    }),
    removeAppointment: builder.mutation({
      query: ({ id }) => ({
        url: `/Appointment/Remove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments'],
    }),
    getAppointment: builder.query({
      query: ({ id }) => `/Appointment/Get?id=${id}`,
    }),
    getAllAppointentsFn: builder.query({
      queryFn: async ({ from, to }, api, extraOptions, baseQuery) => {
        const state = api.getState();
        const allStaff = state.staffSlice.staff;
        try {
          const { data } = await baseQuery({
            url: `/Appointment/GetAll?from=${from}&to=${to}`,
            method: 'GET',
          });
          return {
            data: data.map((r) => ({
              ...r,
              appointmentStaff: r.appointmentStaff.map((memberId) => {
                const foundEmpl = allStaff.find((staff) => staff.staffID == memberId);
                if (foundEmpl) {
                  return {
                    fullName: {
                      fio: `${foundEmpl.fullName_Surname} ${foundEmpl.fullName_Name} ${foundEmpl.fullName_Patronymic}`,
                      surname: foundEmpl.fullName_Surname,
                      name: foundEmpl.fullName_Name,
                      patronimyc: foundEmpl.fullName_Patronymic,
                    },
                    id: foundEmpl.staffID,
                    staffFileUrl: foundEmpl.staffFileUrl,
                  };
                } else {
                  return {
                    fullName: {
                      fio: '',
                    },
                    id: 0,
                    staffFileUrl: '',
                  };
                }
              }),
              text: r.name,
              resource: r.chiefId,
              start: new DayPilot.Date(r.start),
              end: new DayPilot.Date(r.end),
            })),
          };
        } catch (error) {
          return { error };
        }
      },
    }),
    getAppointmentsBySender: builder.query({
      query: (params) => ({
        url: '/Appointment/GetAppointmentSender',
        method: 'GET',
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getAllAppointments: builder.query({
      query: (params) => ({
        url: '/Appointment/GetAll',
        method: 'GET',
        params,
      }),
      providesTags: ['AllAppointments'],
    }),
    editAppointmentByApprover: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/Appointment/Edit/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AllAppointments'],
    }),

    checkAppointmentManager: builder.query({
      query: () => '/Appointment/CheckAppointmentManager',
    }),
    createAppointmentPurpose: builder.mutation({
      query: (body) => ({
        url: '/Appointment/CreatePurpose',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AppointmentPurposes'],
    }),
    getAllAppointmentPurposes: builder.query({
      query: ({ lang }) => `/Appointment/GetAllAppointmentPurpose?lang=${lang}`,
      providesTags: ['AppointmentPurposes'],
    }),
    getAppointmentPurpose: builder.query({
      query: ({ id, lang }) => `/Appointment/GetAppointmentPurpose?Id=${id}&lang=${lang}`,
    }),
    editAppointmentPurpose: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/Appointment/EditPurpose/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AppointmentPurposes'],
    }),
    createManagerSecretary: builder.mutation({
      query: (body) => ({
        url: '/ManagerSecretary/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ManagersAndSecretaries'],
    }),
    getAllManagerSecretary: builder.query({
      query: ({ lang }) => `/ManagerSecretary/GetAll?lang=${lang}`,
      providesTags: ['ManagersAndSecretaries'],
    }),
    editManagerSecretary: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/ManagerSecretary/Edit/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ManagersAndSecretaries'],
    }),
    removeManagerSecretary: builder.mutation({
      query: ({ id }) => ({
        url: `/ManagerSecretary/Remove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ManagersAndSecretaries'],
    }),
  }),
});

export const {
  useGetCalendarListQuery,
  useCreateCalendarMutation,
  useEditCalendarMutation,
  useDeleteCalendarMutation,
  useGetResourceListMutation,
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
  useDeleteResourceMutation,
  useCreateEventMutation,
  useGetEventListMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useAddStaffToEventMutation,
  useRemoveStaffFromEventMutation,
  useCreateAppointmentMutation,
  useEditAppointmentMutation,
  useRemoveAppointmentMutation,
  useGetAppointmentQuery,
  useGetAllAppointentsFnQuery,
  useCreateManagerSecretaryMutation,
  useGetAllManagerSecretaryQuery,
  useEditManagerSecretaryMutation,
  useRemoveManagerSecretaryMutation,
  useGetAppointmentsBySenderQuery,
  useCheckAppointmentManagerQuery,
  useGetAllAppointmentsQuery,
  useEditAppointmentByApproverMutation,
  useCreateAppointmentPurposeMutation,
  useGetAllAppointmentPurposesQuery,
  useGetAppointmentPurposeQuery,
  useEditAppointmentPurposeMutation,
} = resourceApi;
