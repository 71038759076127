const locale = {
  Chats: 'Chatlar',
  CrteateNewGroup: "Yangi guruh qo'shish",
  Cancel: 'Bekor qilish',
  Save: 'Saqlamoq',
  Contacts: 'Kontaktlar',
  Blanks: 'Blankalar',
  Attachments: "Qo'shimchalar",
  DocsName: 'Hujjat nomi',
  CreatedDate: 'Yaratilgan sana',
  Employers: 'Ischilar',
  MySkud: 'My Skudim',
  Departments: "Bo'limlar",
  Users: 'Foydalanuvchilar',
  FullName: 'Ism sharif',
  HrmUsers: 'HRM foydalanuvchilari',
  Actions: 'Harakatlar',
  ConnectToHRM: "HRM bilan bog'lash",
  AddStatus: "Status qo'shish",
  AddUser: "Foydalanuvchini qo'shish",
  Roles: 'Rollar',
  Add: "Qo'shish",
  Submit: 'Yuborish',
  Download: 'Yuklash',
  Search: 'Qidirish',
  Profile: 'Profil',
  Message: 'Xabar',
  Employees: 'Xodimlar',
  Notifications: 'Ogohlantirishlar',
  FullscreenToggle: 'Toʻliq ekranni yoqish/oʻchirish',
  Welcome: 'Xush kelibsiz',
  WelcomeToOurCommunity: 'Jamiyatimizga xush kelibsiz',
  WeСreateUniqueItSolutions:
    "Biz jamiyat va hamkorlarimizga o'z maqsadlariga erishishga yordam beradigan, qulaylikni ta'minlovchi va hayotni yangi imkoniyatlar bilan to'ldirishga yordam beradigan noyob IT-yechimlarni yaratamiz",
  SignIn: 'Kirish',
  Username: 'Foydalanuvchi nomi',
  Password: 'Parol',
  EnterLogin: 'Login kiriting',
  Hint: "Ko'rsatma",
  Press: 'Bosing',
  here: 'shu yerni',
  toShowEducationTour: "ko'rsatma turini ko'rish uchun",
  Further: 'Keyingi',
  Previous: 'Oldingi',
  EnterPassword: 'Parolni kiriting',
  EnterUzAtomMailLogin:
    'UzAtom pochtasiga kirish uchun administratorlardan olingan loginni kiriting',
  EnterUzAtomMailPassword:
    'UzAtom pochtasiga kirish uchun administratordan olingan parolni kiriting',
  PressButton: 'Tugmani bosing',
  PressThisButtonOrEnterButtonOnKeyboard:
    'Ushbu tugmani yoki kompyuteringiz klaviaturasidagi Enter tugmasini bosing',
  Today: 'Bugun',
  InFewDays: 'Yaqin kunlarda',
  Greeting: 'Salom',
  GladToSeeYouInOurTeam: "Sizni jamoamizda ko'rganimizdan xursandmiz",
  YourEnergyAndTalentWillBecomeContribution:
    "Sizning kuchingiz va istedodingiz umumiy ishimizga bebaho hissa bo'ladi",
  ForwardToNewAchievements: 'Yangi yutuqlar sari intiling',
  Start_verb: 'Boshlash',
  YouMustEnterUsername: 'Foydalanuvchi nomini kiriting',
  PasswordIsTooShort: "Parol juda qisqa - kamida 4 ta belgidan iborat bo'lishi kerak",
  DocumentName: 'Hujjat nomi',
  CreationDate: 'Yaratilgan sanasi',
  IncorrectLoginOrPassword: "Siz kiritgan foydalanuvchi nomi yoki parol noto'g'ri",
  InternalDocuments: 'Ichki hujjatlar',
  DownloadMobileApplication: 'Mobil ilovani yuklab olish',
  HappyBirthdayСongratulations:
    'Tug`ilgan kuningiz bilan! Ahil jamoamiz nomidan sizni yorqin kuningiz bilan chin dildan samimiy tabriklaymiz! Sizga ulkan muvaffaqiyatlar, doimiy omad, sihat-salomatlik va hayotingizda cheksiz baxt tilaymiz. Sevimli ishingiz sizga doimo quvonch va zavq olib kelsin. Sizning kasbiy bilimingiz, katta tajribangiz, yangi foydali g‘oyalaringiz hamkorlikdagi faoliyatimizda yuqori natijalarga erishish imkonini bersin.',
  BestRegardsUzAtomTeam: 'Hurmat bilan, UzAtomjamoasi.',
  UzAtom_fullname:
    'O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Atom energiyasi agentligi',
  ScanFromMobileToEnter: 'Kirish uchun mobil ilovadan skan qiling',
  SelectPhase: 'Fazani tanlang',
  SignInWithMyId: 'MyID orqali kirish',
  GettingPermissionForEnter: 'Kirish uchun ruxsat olish',
  AddNews: "Yangilikni qo'shish",
  MyIDAuthError: 'MyID orqali kirishda xato',
  BackToLoginPage: 'Kirish sahifasiga qaytish',
  No: "Yo'q",
  VisitBooking: 'Uchrashuv qabuliga yozilish',
  None: "Yo'q",
  Top: 'Top',
  Volonter: 'Fidoyi',
  Trainee: 'Stajyor',
  Probation: 'Sinov muddati',
  Appointment: 'Qabulga yozilish',
};

export default locale;
