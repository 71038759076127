import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const Appointments = lazy(() => import('./Appointments'));

const AppointmentsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  authRoles: authRoles.user,
  routes: [
    {
      path: 'apps/appointments',
      element: <Appointments />,
    },
  ],
};

export default AppointmentsConfig;
