import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, DialogTitle, IconButton } from '@mui/material';
import { Close, EventRepeat } from '@mui/icons-material';
import moment from 'moment';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useState } from 'react';
import RejectMyAppointmentDialog from './RejectMyAppointmentDialog';

const MyAppointmentDialog = ({ isOpen, appointment, onClose }) => {
  const { t } = useTranslation('translations');
  const [isRejectDialogVisible, setIsRejectDialogVisible] = useState(false);

  const handleDialogsClose = () => {
    setIsRejectDialogVisible(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
        <DialogTitle>
          <div className="flex justify-between">
            {t('Appointment')}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="pb-20 flex flex-col gap-14">
            <div>
              <p className="text-12 text-grey mb-0">{t('TopManager')}</p>
              <div className="flex flex-start gap-14">
                <Avatar
                  src={getAbsoluteFileUrl(appointment?.manager?.imagePath)}
                  className="mt-5"
                  variant="rounded"
                  sx={{ width: 64, height: 64 }}
                />

                <div className="flex items-center mt-2">
                  <div className="text-base capitalize font-inter font-500">
                    {appointment?.manager?.name}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="text-12 text-grey mb-0">{t('Employee')}</p>
              <div className="flex flex-start gap-14">
                <Avatar
                  src={getAbsoluteFileUrl(appointment?.appointmentStaff?.[0]?.imagePath)}
                  className="mt-5"
                  variant="rounded"
                  sx={{ width: 64, height: 64 }}
                />

                <div className="flex items-center mt-2">
                  <div className="text-base font-inter font-500">
                    {appointment?.appointmentStaff?.[0]?.name || t('ImpossibleToIdentify')}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="text-12 text-grey">{t('Status')}</p>
              <p className="text-14">{appointment ? t(appointment.status) : '-'}</p>
            </div>
            <div>
              <p className="text-12 text-grey">{t('FromTime')}</p>
              <p className="text-14">
                {appointment?.start ? moment(appointment.start).format('DD.MM.YYYY HH:mm') : '-'}
              </p>
            </div>
            <div>
              <p className="text-12 text-grey">{t('ToTime')}</p>
              <p className="text-14">
                {appointment?.end ? moment(appointment.end).format('DD.MM.YYYY HH:mm') : '-'}
              </p>
            </div>
            <div>
              <p className="text-12 text-grey">{t('Category')}</p>
              <p className="text-14">{appointment?.purpose}</p>
            </div>
            <div>
              <p className="text-12 text-grey">{t('Reason')}</p>
              <p className="text-14">{appointment?.name}</p>
            </div>
            {appointment && appointment?.statusId === 3 && (
              <div className="mt-8 flex items-center justify-around gap-10 flex-wrap">
                <button
                  onClick={() => setIsRejectDialogVisible(true)}
                  type="button"
                  className="text-[#ffffff] test-12  border-[1px] bg-[#f05454] border-[#E6E5E7] rounded-12 px-12 py-8"
                >
                  <EventRepeat className="mr-5 mb-2" />
                  {t('SendToReApprovement')}
                </button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <RejectMyAppointmentDialog
        appointment={appointment}
        isOpen={isRejectDialogVisible}
        onClose={handleDialogsClose}
      />
    </div>
  );
};

export default MyAppointmentDialog;
