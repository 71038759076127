import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button } from 'antd';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useUser } from 'src/app/hooks/useUser';
import MyAppointmentDialog from './components/MyAppointmentDialog';
import AddMyAppointmentDialog from './components/AddMyAppointmentDialog';
import { useGetAppointmentsBySenderQuery } from 'src/app/services/resourceApi';
import './ScrollbarProfile.css';

const rows = 5;

const MyAppointments = () => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language;
  const [page, setPage] = useState(1);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isAddEditAppointmentDialogVisible, setIsAddEditAppointmentDialogVisible] = useState(false);
  const { data: appointments = [], isLoading } = useGetAppointmentsBySenderQuery({
    lang,
    page,
    rows,
  });
  const {
    user: { staffId },
  } = useUser();

  const totalPages = Math.ceil(appointments?.totalItems / rows);

  const showAddAppointmentDialog = () => {
    setIsAddEditAppointmentDialogVisible(true);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-24">
      <div className="flex justify-between">
        <h4 className="font-inter font-600 text-2xl text-[#16151c] dark:text-white">
          {t('MyAppointments')}
        </h4>
        {staffId > 0 && (
          <div
            onClick={showAddAppointmentDialog}
            className="flex items-center gap-4 px-12 py-8 bg-[#307bfd1f] rounded-8 cursor-pointer hover:bg-[#74a7ff1f]"
          >
            <span className="w-16 h-16 mb-2">
              <FuseSvgIcon className="text-[#317bff]" size={20}>
                heroicons-outline:plus
              </FuseSvgIcon>
            </span>
            <p className="text-[#317bff]">{t('Add')}</p>
          </div>
        )}
      </div>
      <div className="mt-24 flex flex-col overflow-x-scroll scrollbar-horizontal max-h-[480px]">
        <div className="flex items-center justify-between sticky top-0 bg-white dark:bg-dark">
          <h5 className="font-inter md:min-w-[200px] min-w-[100px] leading-6 text-[#818181] dark:text-darkgrey  py-10 border-b-1 border-[#EDEEF3] grow">
            {t('Status')}
          </h5>
          <h5 className="font-inter min-w-[200px] leading-6 text-[#818181] dark:text-darkgrey  py-10 border-b-1 border-[#EDEEF3] grow">
            {t('TopManager')}
          </h5>
          <h5 className="font-inter min-w-[140px] leading-6 text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] grow">
            {t('Time')}
          </h5>
          <h5 className="font-inter min-w-[130px] pl-10 leading-6 text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] grow">
            {t('Reason')}
          </h5>
        </div>
        <div className="grow">
          <ul>
            {appointments?.items?.length === 0 && (
              <div className="text-center mt-10">{t('No')}</div>
            )}
            {appointments?.items?.map((appointment) => (
              <li
                key={appointment.id}
                className="flex items-center cursor-pointer hover:bg-blue-50 dark:hover:bg-darkblue"
                onClick={() => setSelectedAppointment(appointment)}
              >
                <p className="py-16 lg:pl-6 font-inter  basis-1/4 min-w-[120px] ">
                  {appointment.status}
                </p>
                <p className="py-16 font-inter  font-400 basis-1/4 min-w-[120px]">
                  {appointment?.manager?.name}
                </p>
                <p className="py-16 font-inter  font-400 basis-1/4 min-w-[120px]">
                  {appointment?.start ? moment(appointment.start).format('DD.MM.YYYY HH:mm') : ''}
                </p>

                <p className="py-16 lg:pl-24 font-inter  dark:text-white font-400 basis-1/4 min-w-[120px]">
                  {appointment.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {appointments?.totalItems > 0 && (
          <div className="mt-20 flex justify-center items-center">
            <Button
              type="text"
              onClick={() => setPage(page - 1)}
              disabled={page < 2}
              className="dark:text-white"
            >
              <ChevronLeft />
            </Button>
            <span>
              {page} / {totalPages}
            </span>
            <Button
              type="text"
              onClick={() => setPage(page + 1)}
              disabled={page == totalPages}
              className="dark:text-white"
            >
              <ChevronRight />
            </Button>
          </div>
        )}
      </div>
      <MyAppointmentDialog
        isOpen={!!selectedAppointment}
        onClose={() => {
          setSelectedAppointment(null);
        }}
        appointment={selectedAppointment}
        isViewMode={true}
      />
      <AddMyAppointmentDialog
        isOpen={isAddEditAppointmentDialogVisible}
        onClose={() => setIsAddEditAppointmentDialogVisible(false)}
      />
    </div>
  );
};

export default MyAppointments;
