import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMessage from 'src/app/hooks/useMessage';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useEditAppointmentMutation } from 'src/app/services/resourceApi';
import { LoadingButton } from '@mui/lab';

const defaultValues = {
  start: new Date(),
  end: new Date().getTime() + 1800000,
};

const RejectMyAppointmentDialog = ({ appointment, isOpen, onClose }) => {
  const { showMessage } = useMessage();
  const { t } = useTranslation('translations');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: appointment ?? defaultValues,
  });

  const [editAppointment, { isLoading }] = useEditAppointmentMutation();

  const onSubmit = (values) => {
    const appointmentStaff = appointment.appointmentStaff.map((staff) => staff.id);
    editAppointment({
      ...appointment,
      appointmentStaff,
      managerId: appointment?.manager?.id,
      comment: values.comment,
      statusId: 2,
      start: null,
      end: null,
    })
      .unwrap()
      .then((res) => {
        if (res.statusId) {
          showMessage({ message: t('SuccessfullySaved'), variant: 'success' });
          reset();
          onClose();
        }
      })
      .catch((error) => {
        console.error(error);
        showMessage({ message: t('ErrorOccuredOpenBrowserConsole'), variant: 'error' });
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>{t('SendToReApprovement')}</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className="pt-10">
          <div className="flex flex-col gap-16 max-w-[1200px] mb-20">
            <p>{t('EnterAppointmentReApprovementReason')}</p>
            <Controller
              name="comment"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows="2"
                  label={t('Comment')}
                  variant="outlined"
                  required
                  error={!!errors.comment}
                />
              )}
            />
          </div>
          <div className="my-10 ">
            <LoadingButton
              disabled={isLoading || !isValid}
              loading={isLoading}
              type="submit"
              className="w-full"
              variant="contained"
              color="secondary"
            >
              <span className="mx-8">{t('Save')}</span>
            </LoadingButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default RejectMyAppointmentDialog;
