import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const ProfileStuff = () => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation('translations');
  const [profileData] = useOutletContext();
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';
  const transformData = {
    name: profileData?.fullName?.name,
    phone: `${profileData?.contact?.phoneMain || ''}${
      profileData?.contact?.phoneAdditional ? ', ' + profileData?.contact?.phoneAdditional : ''
    }`,
    birthDate: moment(profileData?.passport?.birthDate).format('LL'),
    surname: profileData?.fullName?.surname,
    mail: `${profileData?.contact?.eMail || ''}${
      profileData?.contact?.eMailAdditional ? ', ' + profileData?.contact?.eMailAdditional : ''
    }`,
    department:
      profileData?.placeOfWork?.departmentName && profileData?.placeOfWork?.departmentName[lang],
    position:
      profileData?.placeOfWork?.positionName && profileData?.placeOfWork?.positionName[lang],
  };

  return (
    <div className="p-24">
      <h4 className="font-inter font-600 text-2xl text-[#16151c] dark:text-white">
        {t('Profile')}
      </h4>
      <ul className="mt-24 grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-4 gap-20">
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white capitalize">
            {t('Name')}
          </h6>
          <h3 className="dark:text-darkgrey font-inter font-500 leading-6 capitalize">
            {transformData?.name?.toLowerCase()}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('Surname')}
          </h6>
          <h3 className="dark:text-darkgrey font-inter font-500 leading-6 capitalize">
            {transformData?.surname?.toLowerCase()}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('Birthday')}
          </h6>
          <h3 className="dark:text-darkgrey font-inter font-500 leading-6">
            {transformData?.birthDate}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('PhoneNumber')}
          </h6>
          <h3 className="dark:text-darkgrey font-inter font-500 leading-6">
            {transformData.phone}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('Subdivision')}
          </h6>
          {transformData?.department && (
            <h3 className="dark:text-darkgrey font-inter font-500 leading-6">
              {transformData?.department}
            </h3>
          )}
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('Position')}
          </h6>
          <h3 className=" dark:text-darkgrey font-inter font-500 leading-6">
            {transformData?.position}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]">
          <h6 className="text-base font-inter leading-6 text-darkergrey dark:text-white">
            {t('Email')}
          </h6>
          <h3 className="dark:text-darkgrey font-inter font-500 leading-6">
            {transformData.mail?.toLowerCase()}
          </h3>
        </li>
        <li className="flex flex-col gap-5 pb-10 border-b border-[#A2A1A845]"></li>
      </ul>
    </div>
  );
};

export default ProfileStuff;
