import AppointmentCategoryForm from './components/AppointmentCategoryForm';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import SimpleDialog from '@fuse/core/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { useGetAllAppointmentPurposesQuery } from 'src/app/services/resourceApi';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

const AppointmentCategories = () => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language;
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: categories = [], refetch } = useGetAllAppointmentPurposesQuery({ lang });

  const showNewItemDialog = () => {
    setCurrentCategoryId(null);
    setIsDialogOpen(true);
  };

  const showEditDialog = (id) => {
    setCurrentCategoryId(id);
    setIsDialogOpen(true);
  };

  return (
    <div style={{ display: 'grid' }}>
      <TableContainer>
        <div className="h-[40px] flex flex-row justify-between items-center dark:bg-dark">
          <h2 className="ml-5"> {t('AppointmentCategories')}</h2>
          <Button
            className="mx-8"
            size="small"
            variant="outlined"
            color="secondary"
            onClick={showNewItemDialog}
          >
            <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
            <span className="mx-8">{t('Add')}</span>
          </Button>
        </div>
        <Table style={{ overflow: 'auto' }}>
          <TableBody className=" overflow-y-auto">
            {categories.map((category) => (
              <TableRow key={category.id} className="bg-white hover:bg-grey-200 dark:bg-dark">
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  <div className="text-right">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      className="mr-5"
                      color="info"
                      onClick={() => showEditDialog(category.id)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleDialog
        title={currentCategoryId ? t('EditAppointmentCategory') : t('AddAppointmentCategory')}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <AppointmentCategoryForm
          id={currentCategoryId}
          refetch={refetch}
          onClose={() => setIsDialogOpen(false)}
          isEdit={!!currentCategoryId}
        />
      </SimpleDialog>
    </div>
  );
};

export default AppointmentCategories;
