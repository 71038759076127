import { Delete, Edit } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';

const TopManager = ({ manager, onEdit, onRemove }) => {
  const { t } = useTranslation('translations');

  const handleEditTop = () => {
    onEdit(manager);
  };

  const handleRemoveTop = () => {
    onRemove(manager);
  };
  return (
    <div className="flex flex-col justify-between w-[260px] gap-16 min-h-[340px] p-10 py-24 rounded-md bg-white dark:bg-dark shadow-sm hover:shadow-md relative">
      <div className="flex flex-col items-center ">
        <div className="absolute top-6 right-2">
          <Edit className="text-[#2196f3] mr-6 cursor-pointer" onClick={handleEditTop} />
          <Popconfirm
            className="mr-6"
            title={t('AreYouSureToDeleteClaim')}
            onConfirm={handleRemoveTop}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Delete className="text-red cursor-pointer" />
          </Popconfirm>
        </div>
        <Avatar
          src={getAbsoluteFileUrl(manager?.managerImage)}
          variant="rounded"
          sx={{ width: 130, height: 160 }}
        />
        <div className="text-20 text-center mt-6">{manager.managerName}</div>
        <div className="text-center">{manager.top?.position}</div>
      </div>
      <div className="min-h-[60px]">
        {t('Secretary')}:
        {manager?.secretaryId ? (
          <div className="flex gap-10 mt-6 items-center">
            <Avatar src={getAbsoluteFileUrl(manager?.secretaryImage)} />
            <div>
              <div>{manager?.secretaryName}</div>
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-red">{t('NotAssigned')}</div>
        )}
      </div>
    </div>
  );
};

export default TopManager;
