import { authRoles } from 'src/app/auth';
import TopManagers from './TopManagers';

const TopManagersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/top-managers',
      element: <TopManagers />,
    },
  ],
};

export default TopManagersConfig;
