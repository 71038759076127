import handleError from '@fuse/core/errorHandler';
import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMessage from 'src/app/hooks/useMessage';
import {
  useCreateAppointmentPurposeMutation,
  useEditAppointmentPurposeMutation,
  useGetAppointmentPurposeQuery,
} from 'src/app/services/resourceApi';

const AppointmentCategoryForm = ({ id, isEdit, onClose, refetch }) => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language;
  const [update, { isLoading }] = useEditAppointmentPurposeMutation();
  const [create] = useCreateAppointmentPurposeMutation();
  const { data = null } = useGetAppointmentPurposeQuery({ id, lang }, { skip: !id || !lang });

  const [nameUz, setNameUz] = useState('');
  const [nameRu, setNameRu] = useState('');
  const { showMessage } = useMessage();

  useEffect(() => {
    if (data) {
      setNameUz(data.nameUz);
      setNameRu(data.nameRu);
    }
  }, [data]);

  const handleClose = () => {
    onClose();
    setNameRu('');
    setNameUz('');
    refetch();
  };

  const handleSave = () => {
    const promise = isEdit ? update({ id, nameRu, nameUz }) : create({ nameRu, nameUz });
    promise
      .unwrap()
      .then(() => {
        showMessage({ message: t('SuccessfullySaved'), variant: 'success' });
        handleClose();
      })
      .catch(handleError);
  };

  return (
    <div className=" flex flex-col gap-16 pt-10 max-w-7xl">
      <div className="mb-16">
        <TextField
          value={nameUz}
          onChange={(e) => setNameUz(e.target.value)}
          fullWidth
          label={t('Title') + ' (uzb.)'}
          variant="outlined"
          required
        />
      </div>
      <div className="mb-16">
        <TextField
          value={nameRu}
          onChange={(e) => setNameRu(e.target.value)}
          fullWidth
          label={t('Title') + ' (рус.)'}
          variant="outlined"
          required
        />
      </div>
      <Button
        disabled={isLoading || !nameUz || !nameRu}
        className="w-full"
        variant="contained"
        color="secondary"
        onClick={handleSave}
      >
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </div>
  );
};

export default AppointmentCategoryForm;
