import useMessage from 'src/app/hooks/useMessage';
import { Autocomplete, Button, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUser } from 'src/app/hooks/useUser';
import {
  useCreateAppointmentMutation,
  useGetAllAppointmentPurposesQuery,
  useGetAllManagerSecretaryQuery,
} from 'src/app/services/resourceApi';

const defaultValues = {
  category: '',
  reason: '',
};

const MyAppointmentForm = ({ onClose }) => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language;
  const { showMessage } = useMessage();
  const { user } = useUser();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [selectedChief, setSelectedChief] = useState(null);
  const { data: leaveCategories = [] } = useGetAllAppointmentPurposesQuery({ lang });
  const { data: chiefList = [] } = useGetAllManagerSecretaryQuery({ lang });
  const [addAppointment, { isLoading }] = useCreateAppointmentMutation();

  const onSubmit = async (values) => {
    const { category, reason } = values;
    addAppointment({
      name: reason,
      purposeId: category,
      managerId: selectedChief?.managerId,
      appointmentStaff: [user.staffId],
    })
      .unwrap()
      .then((res) => {
        showMessage({ message: t('SuccessfullySaved'), variant: 'success' });
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        showMessage({ message: t('ErrorOccuredOpenBrowserConsole'), variant: 'error' });
      });
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-10">
      <div className="flex flex-col gap-16 w-[210px] sm:w-[500px] mb-20">
        <Autocomplete
          value={selectedChief}
          getOptionLabel={(option) => option.managerName ?? ''}
          isOptionEqualToValue={(option, _value) => option.id === _value.id}
          options={chiefList}
          rules={{ required: true }}
          renderOption={(_props, option) => (
            <li {..._props} key={option.id}>
              {option.managerName}
            </li>
          )}
          fullWidth
          onChange={(e, _value) => setSelectedChief(_value)}
          renderInput={(params) => (
            <TextField {...params} label={t('TopManager')} error={!selectedChief} />
          )}
        />

        <TextField
          label={t('Category')}
          select
          fullWidth
          rules={{ required: true }}
          className="dark:bg-lightblack"
          {...register('category')}
          error={!!errors.category}
        >
          {leaveCategories.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <Controller
          name="reason"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              minRows="2"
              label={t('Reason')}
              variant="outlined"
              required
              error={!!errors.reason}
            />
          )}
        />
      </div>
      <Button
        disabled={!selectedChief || isLoading}
        type="submit"
        className="w-full"
        variant="contained"
        color="secondary"
      >
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </form>
  );
};

export default MyAppointmentForm;
