import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const shtatApi = createApi({
  reducerPath: 'shtatApi',
  baseQuery: rtkBaseQuery('/staff/Shtat'),
  tagTypes: ['Schedule', 'Positions'],
  endpoints: (builder) => ({
    getPositionsList: builder.query({
      query: () => '/GetPositionsList',
    }),
    createPosition: builder.mutation({
      query: (body) => ({
        url: '/CreatePosition',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Schedule'],
    }),
    deletePosition: builder.mutation({
      query: (id) => ({
        url: `/DeletePosition?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Schedule'],
    }),
    updatePosition: builder.mutation({
      query: (body) => ({
        url: '/UpdatePosition',
        method: 'POST',
        body,
      }),
    }),
    getPositionById: builder.mutation({
      query: (body) => ({
        url: '/GetPositionById',
        method: 'GET',
        body,
      }),
    }),
    getShtatScheduleList: builder.query({
      query: () => '/GetShtatScheduleList',
      providesTags: ['Schedule'],
    }),
    // GetPositionsByDepartment
    getPositionsByDepartment: builder.query({
      query: (id) => `/GetPositionsByDepartment?id=${id}`,
    }),

    getPositionsByDepartmentNew: builder.query({
      query: (id) => `/GetPositionsByDepartmentNew?id=${id}`,
    }),

    createPersonnelPlacement: builder.mutation({
      query: (body) => ({
        url: '/CreatePersonnelPlacement',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Schedule'],
    }),
    deletePersonnelPlacement: builder.mutation({
      query: (id) => ({
        url: `/DeletePersonnelPlacement?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Schedule'],
    }),
    updatePersonnelPlacement: builder.mutation({
      query: (body) => ({
        url: '/UpdatePersonnelPlacement',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Schedule'],
    }),
    setSupervisorPosition: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/SetSupervisorPosition?id=${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Schedule'],
    }),
    fetchSubordinateDepartments: builder.mutation({
      query: ({ superviserId, ...body }) => ({
        url: `/GetSubordinatesDepartments?superviserId=${superviserId}`,
        method: 'POST',
        body,
      }),
    }),
    getEmployeeCountByDepartment: builder.query({
      query: (params) => ({
        url: 'GetEmployeeCountByDepartment',
        params,
      }),
    }),
    checkSubordinate: builder.query({
      query: () => 'CheckSubordinate',
    }),
    getSubordinateEmployees: builder.query({
      query: ({ lang }) => `GetSubordinateEmployees?lang=${lang}`,
    }),
  }),
});

export const {
  useGetPositionsListQuery,
  useCreatePositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
  useGetPositionByIdMutation,
  useGetShtatScheduleListQuery,
  useGetPositionsByDepartmentQuery,
  useGetPositionsByDepartmentNewQuery,
  useCreatePersonnelPlacementMutation,
  useDeletePersonnelPlacementMutation,
  useUpdatePersonnelPlacementMutation,
  useSetSupervisorPositionMutation,
  useFetchSubordinateDepartmentsMutation,
  useGetEmployeeCountByDepartmentQuery,
  useCheckSubordinateQuery,
  useGetSubordinateEmployeesQuery,
} = shtatApi;
