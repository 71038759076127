import ReferencesConfig from './references/ReferencesConfig';
import RolesConfig from './roles/RolesConfig';
import UsersConfig from './users/UsersConfig';
import mapCRUDAppConfig from './mapFloor/CRUDMapAppConfig';
import LogsConfig from './logs/LogsConfig';
import ClaimExecutorsConfig from './claimExecutors/ClaimExecutorsConfig';
import TopManagersConfig from './top-managers/TopManagersConfig';

const adminConfig = [
  RolesConfig,
  UsersConfig,
  ReferencesConfig,
  mapCRUDAppConfig,
  LogsConfig,
  ClaimExecutorsConfig,
  TopManagersConfig,
];

export default adminConfig;
