import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderFullName } from '../helpers/utils';
import { useGetStaffListQuery } from '../services/staffApi';

const StaffSelector = ({ value, onChange, label = 'Employee' }) => {
  const { t } = useTranslation('translations');
  const { data: staffList } = useGetStaffListQuery();
  const [currentStaff, setCurrentStaff] = useState(value ?? null);

  useEffect(() => onChange(currentStaff), [currentStaff, onChange]);

  if (!staffList?.length) return null;

  return (
    <Autocomplete
      value={currentStaff}
      getOptionLabel={(option) => renderFullName(option) ?? ''}
      isOptionEqualToValue={(option, _value) => option.id === _value.id}
      options={staffList}
      renderOption={(_props, option) => (
        <li {..._props} key={`${option.staffID}${option.positionId}${option.applicationUserId}`}>
          {renderFullName(option)}
        </li>
      )}
      fullWidth
      onChange={(e, _value) => setCurrentStaff(_value)}
      renderInput={(params) => <TextField {...params} size="small" label={t(label)} />}
    />
  );
};

export default StaffSelector;
