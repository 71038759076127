import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, IconButton } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import StaffSelector from 'app/shared-components/StaffSelector';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useEffect, useState } from 'react';
import useMessage from 'src/app/hooks/useMessage';
import {
  useCreateManagerSecretaryMutation,
  useEditManagerSecretaryMutation,
} from 'src/app/services/resourceApi';

const TopManagerAddEditDialog = ({ manager, isOpen, onClose, refetch }) => {
  const [top, setTop] = useState(null);
  const [secretary, setSecretary] = useState(null);
  const [isSecretarySelectorVisible, setIsSecretarySelectorVisible] = useState(false);
  const [isTopSelectorVisible, setIsTopSelectorVisible] = useState(false);
  const { showMessage } = useMessage();

  const [createTop, { isLoadin: isCreating }] = useCreateManagerSecretaryMutation();
  const [editTop, { isLoading: isEditing }] = useEditManagerSecretaryMutation();

  useEffect(() => {
    if (manager) {
      if (manager.managerId) {
        setTop({
          id: manager.managerId,
          fullName: manager.managerName,
          image: manager.managerImage,
        });
      }
      if (manager.secretaryId) {
        setSecretary({
          id: manager.secretaryId,
          fullName: manager.secretaryName,
          image: manager.secretaryImage,
        });
      }
    }
  }, [manager]);
  const { t } = useTranslation('translations');
  const onSave = () => {
    if (isCreating || isEditing) return;
    const promise = manager?.managerId
      ? editTop({
          id: manager.id,
          managerId: top.id,
          secretaryId: secretary ? secretary.id : null,
        })
      : createTop({
          managerId: top.id,
          secretaryId: secretary ? secretary.id : null,
        });

    promise
      .unwrap()
      .then((res) => {
        showMessage({ message: t('SucessfullySaved'), variant: 'success' });
        handleDialogClose();
      })
      .catch((err) => {
        console.error(err);
        showMessage({ message: t('ErrorWhileSaving'), variant: 'error' });
      });
  };

  const handleRemoveTop = () => {
    setTop(null);
  };

  const handleRemoveSecretary = () => {
    setSecretary(null);
  };

  const handleChooseSecretary = () => {
    setIsSecretarySelectorVisible(true);
  };

  const handleChooseTop = () => {
    setIsTopSelectorVisible(true);
  };

  const handleDialogClose = () => {
    setTop(null);
    setSecretary(null);
    onClose();
  };

  const handleSetSecretary = (secretary) => {
    if (secretary) {
      const {
        staffID: id,
        fullName_Name: name,
        fullName_Patronymic: patronymic,
        fullName_Surname: surname,
        staffFileUrl: image,
      } = secretary;
      setSecretary({
        id,
        fullName: `${surname} ${name} ${patronymic}`,
        image,
      });
      setIsSecretarySelectorVisible(false);
    }
  };

  const handleSetTop = (top) => {
    if (top) {
      const {
        staffID: id,
        fullName_Name: name,
        fullName_Patronymic: patronymic,
        fullName_Surname: surname,
        staffFileUrl: image,
      } = top;
      setTop({
        id,
        fullName: `${surname} ${name} ${patronymic}`,
        image,
      });
      setIsTopSelectorVisible(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleDialogClose}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>{t('AddTopManagerAndSecretary')}</div>
          <IconButton onClick={handleDialogClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="mb-24">
            <p className="mb-6">{t('TopManager')}</p>
            {top ? (
              <div className="flex flex-wrap gap-10 items-center">
                <Avatar
                  src={getAbsoluteFileUrl(top?.image)}
                  variant="rounded"
                  sx={{ width: 72, height: 72 }}
                />
                <div className="flex items-center">
                  <div>{top?.fullName}</div>
                  <div className="mx-6">
                    <Delete className="text-red cursor-pointer" onClick={handleRemoveTop} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <span className=" text-[#2196f3] cursor-pointer" onClick={handleChooseTop}>
                  {t('ChooseTopManager')}
                </span>
                {isTopSelectorVisible && (
                  <StaffSelector label="TopManager" onChange={handleSetTop} />
                )}
              </div>
            )}
          </div>
          <div className="mb-16">
            <p className="mb-6">{t('Secretary')}</p>
            {secretary ? (
              <div className="flex flex-wrap gap-10 items-center">
                <Avatar
                  src={getAbsoluteFileUrl(secretary?.image)}
                  variant="circle"
                  sx={{ width: 64, height: 64 }}
                />
                <div className="flex items-center">
                  <div>{secretary?.fullName}</div>
                  <div className="mx-6">
                    <Delete className="text-red cursor-pointer" onClick={handleRemoveSecretary} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <span className=" text-[#2196f3] cursor-pointer" onClick={handleChooseSecretary}>
                  {t('ChooseSecretary')}
                </span>
                {isSecretarySelectorVisible && (
                  <StaffSelector label="Secretary" onChange={handleSetSecretary} />
                )}
              </div>
            )}
          </div>
          <div>
            <Button
              disabled={!top || !secretary}
              className="w-full"
              onClick={onSave}
              variant="contained"
              color="secondary"
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TopManagerAddEditDialog;
