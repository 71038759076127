import { useState } from 'react';
import TopManager from './components/TopManager';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import TopManagerAddEditDialog from './components/TopManagerAddEditDialog';
import useMessage from 'src/app/hooks/useMessage';
import handleError from '@fuse/core/errorHandler';
import {
  useGetAllManagerSecretaryQuery,
  useRemoveManagerSecretaryMutation,
} from 'src/app/services/resourceApi';

const TopManagers = () => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language;
  const [isSelectTopDialogVisible, setIsSelectTopDialogVisible] = useState(false);
  const [selectedTop, setSelectedTop] = useState(null);

  const { data: topManagers = [] } = useGetAllManagerSecretaryQuery({ lang });
  const [removeTop, { isLoading: isRemoving }] = useRemoveManagerSecretaryMutation();

  const { showMessage } = useMessage();

  const showTopSelectDialog = () => {
    setIsSelectTopDialogVisible(true);
  };

  const showTopEditDialog = (topManager) => {
    setSelectedTop(topManager);
    setIsSelectTopDialogVisible(true);
  };

  const handleRemoveTop = (manager) => {
    if (!manager || isRemoving) return;
    removeTop({ id: manager.id }).then((res) => {
      showMessage({
        message: t('SuccessfullyDeleted'),
        variant: 'success',
      }).catch((err) => {
        handleError(err);
      });
    });
  };

  const handleAddEditDialogClose = () => {
    setSelectedTop(null);
    setIsSelectTopDialogVisible(false);
  };

  return (
    <div>
      <div className="ml-12 mt-12 flex justify-between items-center">
        <h1 className="text-24 md:text-28 tracking-tight leading-none">{t('TopManagers')}</h1>
        <Button
          className="mx-8"
          size="small"
          variant="outlined"
          color="secondary"
          onClick={showTopSelectDialog}
        >
          <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
          <span className="mx-8">{t('Add')}</span>
        </Button>
      </div>
      <div className="flex flex-wrap gap-16 p-16 ">
        {topManagers.map((manager) => (
          <TopManager
            key={manager.id}
            manager={manager}
            onEdit={showTopEditDialog}
            onRemove={handleRemoveTop}
          />
        ))}
      </div>
      <TopManagerAddEditDialog
        manager={selectedTop}
        isOpen={isSelectTopDialogVisible}
        onClose={handleAddEditDialogClose}
        refetch={() => {}}
      />
    </div>
  );
};

export default TopManagers;
